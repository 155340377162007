import React from "react";
import Headbar from "./components/Headbar";
import { Box, Grid } from "@mui/material";
import SingleTape from "./components/SingleTape";
import { TAPE } from "services/constants";
import useApiQuery from "hooks/useApiQuery";
import NoData from "components/NoData";

const Tape = () => {

  const {
    isLoading,
    error,
    data: apiResponse,
  } = useApiQuery({ queryKey: "tapes", url: TAPE + "list" });
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  console.log(apiResponse, 'api resoponse...')

  return <Box>
    <Headbar />
    <Box mt={6}>
      <Grid container spacing={2} mt={3}>
        {apiResponse &&
          (apiResponse.data.tapes.length > 0 ? (
            apiResponse.data.tapes.map((tapeData) => (
              <Grid width={1} key={tapeData._id} item sm={6} md={6} lg={4}>
                <SingleTape {...tapeData} />
              </Grid>
            ))
          ) : (
            <NoData />
          ))}
      </Grid>
    </Box>
  </Box>;
};

export default Tape;
