import React from "react";
import { Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

import SidebarItem from "./SibeBarItem";

import { StyledList, StyledBox } from "./style";

const Sidebar = ({ handleDrawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [openState, setOpenState] = React.useState({});

  const handleToggle = (key) => {
    setOpenState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (handleDrawerToggle) {
      handleDrawerToggle();
    }
  };

  return (
    <Box>
      <StyledBox>
        <img src="/logos/Logo.png" alt="Logo" />
      </StyledBox>
      <StyledList component="nav" aria-labelledby="nested-list-subheader">
        <SidebarItem
          icon="/icons/home-smile.svg"
          iconActive="/icons/home-active.svg"
          iconHover="/icons/home-active.svg"
          text="Dashboard"
          path="/dashboard"
          onClick={() => handleNavigation("/")}
          isActive={location.pathname === "/"}
        />
        <SidebarItem
          icon="/icons/container.svg"
          text="Content Manager"
          nestedItems={[
            {
              text: "After the Whistle",
              path: "/whistle",
              onClick: handleNavigation,
            },
            {
              text: "Blogs",
              path: "/blogs",
              onClick: handleNavigation,
            },
            {
              text: "BookClub",
              path: "/bookclub",
              onClick: handleNavigation,
            },
            {
              text: "Clips",
              path: "/clips",
              onClick: handleNavigation,
            },
            {
              text: "A-Z",
              path: "/library",
              onClick: handleNavigation,
            },
            {
              text: "BluePrint",
              path: "/blueprint",
              onClick: handleNavigation,
            },
          ]}
          nestedOpen={openState.contentManagerOpen}
          onClick={() => handleToggle("contentManagerOpen")}
          isActive={location.pathname.startsWith("/content-manager")}
        />
        <SidebarItem
          icon="/icons/diamond-01.svg"
          text="Paid Content"
          nestedItems={[
            {
              text: "Lounge",
              path: "/lounge",
              onClick: handleNavigation,
            },
            {
              text: "Tape",
              path: "/tape",
              onClick: handleNavigation,
            },
            {
              text: "Practice",
              path: "/practice",
              onClick: handleNavigation,
            },
          ]}
          nestedOpen={openState.paidContentOpen}
          onClick={() => handleToggle("paidContentOpen")}
          isActive={location.pathname.startsWith("/paid-content")}
        />
        <SidebarItem
          icon="/icons/users.svg"
          text="User Managment"
          iconActive="/icons/users.svg"
          iconHover="/icons/users.svg"
          nestedItems={[
            {
              text: "User List",
              path: "/user-managment",
              onClick: () => handleNavigation("/user-managment")
            },
            {
              text: "Add User",
              path: "/user/add",
              onClick: handleNavigation,
            },
          ]}
          nestedOpen={openState.userManagerOpen}
          onClick={() => handleToggle("userManagerOpen")}
          isActive={location.pathname.startsWith("/users")}
        />
        {/* Faqs */}
        <SidebarItem
          icon="/icons/diamond-01.svg"
          text="Vor Ame Plans"
          iconActive="/icons/diamond-01.svg"
          iconHover="/icons/diamond-01.svg"
          nestedItems={[
            {
              text: "Plans",
              path: "/plans",
              onClick: handleNavigation,
            },
            {
              text: "Discounted Plans",
              path: "/promos",
              onClick: handleNavigation,
            },
            {
              text: "Discount Promotions",
              path: "/promotions",
              onClick: handleNavigation,
            },
          ]}
          nestedOpen={openState.verome}
          onClick={() => handleToggle("verome")}
          isActive={location.pathname.startsWith("/users")}
        />

        <SidebarItem
          icon="/icons/help.svg"
          iconActive="/icons/help-active.svg"
          iconHover="/icons/help-active.svg"
          text="FQAs"
          path="/faqs"
          onClick={() => handleNavigation("/faqs")}
          isActive={location.pathname === "/faq"}
        />
      </StyledList>
    </Box>
  );
};

export default Sidebar;
