import React from "react";
import { FAQ } from "services/constants";
import SingleFaq from "./components/SingleFaq";
import AddFaq from "./components/AddFaq";
import { Box } from "@mui/material";
import NoData from "components/NoData";
import useApiQuery from "hooks/useApiQuery";

const Faqs = () => {
  const {
    isLoading,
    error,
    data: apiResponse,
  } = useApiQuery({ queryKey: "faqs", url: FAQ + "list" });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <AddFaq />
      <Box display="flex" flexDirection="column" gap={2} mt={3}>
        {apiResponse &&
          (apiResponse.data.faqs.length > 0 ? (
            apiResponse.data.faqs.map((faqData) => (
              <SingleFaq key={faqData.key} {...faqData} />
            ))
          ) : (
            <NoData />
          ))}
      </Box>
    </div>
  );
};

export default Faqs;
