import api from "./axios";

export const apiRequestFn = async ({ method = "get", url, data, ...rest }) => {
  const apiResponse = await api({
    method,
    url,
    data,
    ...rest,
  });
  return apiResponse.data;
};


export function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  
  return result;
}