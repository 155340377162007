import { styled } from "@mui/material/styles";
import { CardMedia, Card } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  padding: 10,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    borderRadius: theme.spacing(2),
    padding: 10,
  },
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  objectFit: "cover",
  height: 250,
  width: "100%",
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
  },
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));
