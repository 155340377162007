import React from "react";
import { Grid, Stack } from "@mui/material";
import AddPromotion from "./components/AddPromotion";
import NoData from "components/NoData";
import useApiQuery from "hooks/useApiQuery";
import { PROMOTION } from "services/constants";
import SinglePromotion from "./components/SinglePromotion";

const Promotions = () => {
  const {
    isLoading,
    error,
    data: apiResponse,
  } = useApiQuery({ queryKey: "promotions", url: PROMOTION + "list" });


  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <AddPromotion />
      {apiResponse &&

        (apiResponse.data.length > 0 ? (
          <Stack mt='27.5px' direction='column' gap='21px'>
            {
              apiResponse.data.map((promotionData) => (
                <SinglePromotion key={promotionData._id} {...promotionData} />
              ))
            }
          </Stack>

        ) : (
          <NoData />
        ))}
    </div>
  );
};

export default Promotions;
