export { default as Login } from "./singin";
export { default as ForgotPassword } from "./forgotPassword";
export { default as ResetPassword } from "./reset-password";
export { default as NotFound } from "./404";
export { default as Home } from "./home";
export { default as Blogs } from "./blog";
export { default as FAQs } from "./faq";
export { default as BookClub } from "./book-club";
export { default as Clips } from "./clips";
export { default as Library } from "./library";
export { default as Whistle } from "./whistle";
export { default as BluePrint } from "./blue-print";
export { default as Lounge } from "./lounge";
