import React from "react";
import { Grid } from "@mui/material";
import AddPromo from "./components/AddPromo";
import NoData from "components/NoData";
import useApiQuery from "hooks/useApiQuery";
import { PROMO } from "services/constants";
import SinglePromo from "./components/SinglePromo";

const Promotions = () => {
  const {
    isLoading,
    error,
    data: apiResponse,
  } = useApiQuery({ queryKey: "promos", url: PROMO + "list" });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <AddPromo />
      <Grid container spacing={2} mt={3}>
        {apiResponse &&
          (apiResponse.data.length > 0 ? (
            apiResponse.data.map((promoData) => (
              <Grid key={promoData._id} item sm={6}>
                <SinglePromo {...promoData} />
              </Grid>
            ))
          ) : (
            <NoData />
          ))}
      </Grid>
    </div>
  );
};

export default Promotions;
