import { styled } from "@mui/material/styles";
import { CardMedia, Card } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  borderRadius: theme.spacing(3),
  height: 320,
  padding: 10,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    borderRadius: theme.spacing(2),
    padding: 10,
    height: 400,
  },
}));

export const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  objectFit: "cover",
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(20),
    // marginTop: theme.spacing(1),
  },
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));
