import React from "react";
import parse from "html-react-parser";
import { StyledDescription } from "./style";

const CustomDescriptionParser = ({ description, limit }) => {
  return (
    <StyledDescription variant="body4" limitLines={limit}>
      {parse(description)}
    </StyledDescription>
  );
};

export default CustomDescriptionParser;
