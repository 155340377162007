import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

export const StyledCard = styled(Card)(({ theme, bgColor }) => ({
  borderRadius: theme.spacing(1),
  height: "147px",
  padding: 12,
  background: bgColor,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    borderRadius: theme.spacing(2),
    padding: 10,
    height: "auto",
  },
}));
