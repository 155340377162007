import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import {
  Login,
  NotFound,
  Home,
  ForgotPassword,
  ResetPassword,
  Blogs,
  BookClub,
  Clips,
  Library,
  Whistle,
  BluePrint,
  Lounge,
} from "../pages";
import { AuthLayout, AdminLayout } from "../layout/i_ndex";
import authHandler from "../managers/auth";
import Plans from "pages/plan";
import Faqs from "pages/faq";
import DiscountPlans from "pages/promo";
import UserManagement from "pages/user-managment";
import AddUser from "pages/users/add-user";
import Users from "pages/users";
import Practice from "pages/practice";
import Tape from "pages/tape";
import Promotions from "pages/promotion";

const Routing = () => {
  // For admin routes
  const ProtectedRoute = () => {
    const token = authHandler.getToken();

    if (!token) {
      return <Navigate to="/login" replace />;
    }

    return <Outlet />;
  };

  return (
    <Router>
      <Routes>
        {/* Auth Routes */}
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/setnewpassword/:id" element={<ResetPassword />} />
        </Route>

        {/* Admin Routes */}
        <Route element={<ProtectedRoute />}>
          <Route element={<AdminLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/tape" element={<Tape />} />
            <Route path="/practice" element={<Practice />} />
            <Route path="/users/list" element={<Users />} />
            <Route path="/user/add" element={<AddUser />} />
            <Route path="/bookclub" element={<BookClub />} />
            <Route path="/clips" element={<Clips />} />
            <Route path="/settings" element={<Home />} />
            <Route path="/user-managment" element={<UserManagement />} />
            <Route path="/faqs" element={<Faqs />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/promos" element={<DiscountPlans />} />
            <Route path="/promotions" element={<Promotions />} />
            <Route path="/library" element={<Library />} />
            <Route path="/whistle" element={<Whistle />} />
            <Route path="/blueprint" element={<BluePrint />} />
            <Route path="/lounge" element={<Lounge />} />
          </Route>
        </Route>

        {/* 404 Page */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
