import React from "react";
import { Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";

import { StyledTab, StyledTabList } from "./style";

const MyTabsComponent = ({ tabOptions, tabValue, handleChange }) => {
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
          <StyledTabList
            onChange={handleChange}
            aria-label="Library A-Z Tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabOptions.map((letter) => (
              <StyledTab key={letter} label={letter} value={letter} />
            ))}
          </StyledTabList>
        </Box>
      </TabContext>
    </Box>
  );
};

export default MyTabsComponent;
