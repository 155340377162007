import React from "react";
import { Box, Typography } from "@mui/material";

const NoData = ({ message = "No Data Available" }) => {
  return (
    <Box
      my={1.5}
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={1}
    >
      <Typography
        textAlign="left"
        fontSize="14px"
        variant="h6"
        fontWeight={600}
      >
        {" "}
        {message}
      </Typography>
    </Box>
  );
};

export default NoData;
