import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";

// Helper function to get the first page of a PDF as an image
export const getFirstPageAsImage = async (pdfUrl) => {
  try {
    // Set the worker path
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.5.136/pdf.worker.min.js`;

    const loadingTask = getDocument(pdfUrl);
    const pdf = await loadingTask.promise;

    const page = await pdf.getPage(1);

    // Set up canvas to render the page
    const viewport = page.getViewport({ scale: 1 });
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    // Render the page
    await page.render(renderContext).promise;

    const imageUrl = canvas.toDataURL();

    return imageUrl;
  } catch (error) {
    console.error("Error loading PDF:", error);
    return null;
  }
};
