import { useMutation } from "react-query";
import { apiRequestFn } from "../utils";

const useApiMutation = (otherOptions = {}) =>
  useMutation({
    mutationFn: ({ method = "post", url, data, ...rest }) =>
      apiRequestFn({ method, url, ...(data && { data }), ...rest }),
    ...otherOptions,
  });

export default useApiMutation;
